import api from "./api";

const admins = {
    getAdmins(query) {
        return api.get("/admins", {params: query});
    },
    getAdmin(id) {
        return api.get(`/admins/${id}`);
    },
    postAdmin(body) {
        return api.post("/admins", body);
    },
    putAdmin(id, body) {
        return api.put(`/admins/${id}`, body);
    },
    deleteAdmin(id) {
        return api.delete(`/admins/${id}`);
    },
};

export default admins;
