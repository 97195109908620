<template>
    <div class="super-admins">
        <MainHeader
            v-if="listAdmins.admins"
            class="super-admins__header"
            title="Администраторы"
            :number="listAdmins.admins.length ? listAdmins.admins_count : ''"
            btnText="Новый администратор"
            @btnClick="$router.push('/super-admins/new')"
        />
        <div class="super-admins__row">
            <VSegmentedControl
                :items="filteringOptions"
                :modelValue="activeFilter"
                @update:modelValue="filtration"
            />
            <VDefaultInput
                v-model="search"
                @update:modelValue="adminSearch"
                placeholder="Поиск администратора"
                border
                class="super-admins__search"
            >
                <template #iconLeft>
                    <img src="@/assets/images/icons/search.svg" alt="search"/>
                </template>
                <template #iconRight>
                    <div
                        class="super-admins__search-clear"
                        v-if="search"
                        @click="clearSearch"
                    >
                        <img src="@/assets/images/icons/x.svg" alt="search"/>
                    </div>
                </template>
            </VDefaultInput>
        </div>

        <div class="super-admins__no-content" v-if="errorGet">
            <VNoContent
                title="Ошибка"
                description="Произошла ошибка. Пожалуйста, попробуйте позже"
            />
        </div>

        <div class="companies__content" v-if="!errorGet && listAdmins.admins">
            <div class="super-admins__cards" v-if="listAdmins.admins.length">
                <SuperAdminCard
                    class="super-admins__card"
                    v-for="admin in listAdmins.admins"
                    :key="admin.id"
                    :card="admin"/>
            </div>
            <div class="super-admins__no-content" v-else>
                <VNoContent
                    v-if="!search"
                    title="Список администраторов пуст"
                    description="По выбранному фильтру ничего не найдено"
                />
                <VNoContent
                    v-if="search"
                    title="Ничего не найдено"
                    description="Проверьте пожалуйста, правильно ли вы написали название"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import admins from "@/api/admins";
import {ref, onMounted} from "vue";

import MainHeader from "@/components/MainHeader";
import SuperAdminCard from '@/components/SuperAdminCard';

const filteringOptions = [
    {
        id: "all",
        label: "Все",
    },
    {
        id: "0",
        label: "Активные",
    },
    {
        id: "1",
        label: "Заблокированные",
    },
];
const activeFilter = ref("all");
const search = ref("");
const errorGet = ref(false)

const listAdmins = ref([])
const getAdmins = async (params) => {
    try {
        listAdmins.value = await admins.getAdmins(params)
    } catch (error) {
        errorGet.value = true
        console.log(error)
    }
}

onMounted(() => getAdmins())

const filtration = (val) => {
    if (val === activeFilter.value) return;
    activeFilter.value = val;
    search.value = "";

    if (val === "all") {
        getAdmins();
    } else {
        getAdmins({is_blocked: val});
    }
};
const adminSearch = () => {
    if (search.value.length < 3) {
        getAdmins({search: '', is_blocked: activeFilter.value !== 'all' ? activeFilter.value : ''});
        return
    }
    getAdmins({search: search.value, is_blocked: activeFilter.value !== 'all' ? activeFilter.value : ''});
};
const clearSearch = () => {
    search.value = "";

    if (activeFilter.value !== 'all') {
        getAdmins({is_blocked: activeFilter.value});
    } else {
        getAdmins()
    }
};
</script>

<style scoped lang="scss">
.super-admins {
    &__header {
        margin-top: 32px;
    }

    &__row {
        margin-top: 24px;
        margin-bottom: 32px;
        display: flex;
        justify-content: space-between;
    }

    &__search {
        width: 437px;

        &::v-deep(.v-input__input) {
            &::placeholder {
                color: $dark-blue;
                opacity: 1;
            }
        }
    }

    &__search-clear {
        cursor: pointer;
    }

    &__cards {
        margin-top: -1.5%;
        margin-left: -32px;
        display: flex;
        flex-wrap: wrap;
    }

    &__card {
        width: calc(33.333% - 32px);
        margin-top: 1.5%;
        margin-left: 32px;
    }

    &__no-content {
        width: 100%;
        height: calc(100vh - 175px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
